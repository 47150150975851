import { Link } from 'gatsby';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import React, { useState } from 'react'
import { getLink } from '../../utils/helper';
import CustomLink from '../CustomLink';
import { CustomComponent } from '../CustomRenderFunction';
import FaqMeta from '../FaqMeta';
import './pricing.scss';

const Pricing = ({ story }) => {
    const { content: Content } = story
    const { title, description, label, label_link, subtitle, subtitile_description, faq_block, pricing, button } = Content
    const [active_indexes, setIndexes] = useState([])
    const [temp, setTemp] = useState('0')
    const changeHandler = (e) => setTemp(e.target.value)
    return (
        <div className='pricing-section'>
            <div className='container'>
                <div className='pricing-inner-section'>
                    <div className='price-top-section'>
                        <div className='pricing-header'>
                            <h1> {title} </h1>
                            <p> {description} <Link id={"my_custom_link"} > {label} </Link> </p>
                        </div>
                        <div className='price-filter-range'>
                            <div className='price-subtitle'>
                                <h6>Choose rows per month (in millions)</h6>
                                <p>Your unit costs decrease as your usage increases.</p>
                            </div>
                            {/* <input type="range" id="vol" name="vol" min="0" max="10" step="1" className='slider-dot'/> */}
                            {/* <div className='price-filter-items'>
                                { pricing?.map( item => {
                                    const {Content: {content}, lable: data, price} = item
                                    return ( 
                                        <>
                                        
                                        <div className='price-filter-item'>{data} </div>
                                        { render(content?.map( item => {
                                            const { attrs: {body} } = item
                                            return (
                                                body?.map( i => {
                                                    console.log(i, "i");
                                                    return (
                                                        render(i, {
                                                            blokResolvers: {
                                                                ["Pricing_Table"]: (props) => {
                                                                    console.log(props, "ppppppp");
                                                                }
                                                            }
                                                        })
                                                    )
                                                }) 
                                            )
                                        })) }
                                    </> )
                                }) }
                                </div> */}
                            <input type="range" id="vol" name="vol" min="0" max={pricing.length-1} step="1" className='slider-dot' value={temp} onChange={(e) => changeHandler(e)} />
                            <div className='price-filter-items'>
                                {pricing?.map((item, idx) => {
                                    const { Content, lable: data, price } = item
                                    return (
                                        <div className="price-filter-item" key={idx}>
                                            {data}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='price-filter-button'>
                            <p>Estimated price</p>
                            <div className='price-tag'>
                                {pricing?.map((item, index) => ((temp?.toString() === index?.toString()) && (<div key={index} className="price-tag-container"><h4>{item?.price}</h4><span>/ Month</span></div>)))}
                            </div>
                            {Array.isArray(button) && button.map(btn => <CustomLink {...btn.Link} className='btn'>{btn.Label}</CustomLink>)}
                        </div>
                        <div className='table-cover'>
                            {pricing?.filter((i, idx) => idx == temp).map((item, idx) => {
                                const { Content } = item
                                return (
                                    <div style={{}} key={idx}>
                                        <CustomComponent content={Content} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='faqs_section'>
                        <h2> Frequently asked questions </h2>
                        <FaqMeta
                            questions={faq_block?.map(({question, answer}) => ({ question, answer }))}
                        />
                        {faq_block?.map((item, idx) => {
                            return (
                                <div className={active_indexes?.includes(idx) ? 'faq-cover active' : 'faq-cover'} >
                                    <StoryblokComponent blok={item} onChange={(e) => { active_indexes.includes(idx) ? setIndexes(active_indexes.filter(i => i !== idx)) : setIndexes([...active_indexes, idx]) }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Pricing