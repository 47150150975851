import { Link } from "gatsby";
import React from "react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import { getLink, Table } from "../../utils/helper";
import PricingTable from "../PricingTable";
import WistiaPlayer from "../WistiaPlayer";
import "./customTable.scss";

export const CustomComponent = ({ content }) => {
  return render(content, {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { linktype, href, target } = props;
        if (linktype === "email") {
          // Email links: add `mailto:` scheme and map to <a>
          return <a href={`mailto:${href}`}>{children}</a>;
        }
        if (href.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <a href={href} target={target}>
              {children}
            </a>
          );
        }
        // Internal links: map to <Link>
        return (
          <Link to={getLink(href)}>
            <a>{children}</a>
          </Link>
        );
      },
    },
    blokResolvers: {
      ["Pricing_Table"]: (props) => {
        const {
          table: { tbody: body, thead: head },
          collapsible,
        } = props;
        return (
          <div className="price-table">
            <PricingTable head={head} body={body} collapsible={collapsible} />
          </div>
        );
      },
      ["pricing_table"]: (props) => {
        return (
          <>
            {props.title && <h4>{props.title}</h4>}
            <CustomComponent content={props.content} />
          </>
        );
      },
      ["VideoEmbed"]: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.source }} />;
      },
      ["wistia_player"]: (props) => {
        return <WistiaPlayer blok={props} key={props._uid} />;
      },
    },
  });
};
