import React, { useContext } from "react"
import Page from '../components/Page'
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Pricing from "../components/Pricing"
import LastBanner from "../components/LastBanner"
import { graphql, useStaticQuery } from "gatsby"
import { parse } from "../utils/helper"
import { GlobalContext } from "../context"

export default function PricingPage({ pageContext, location }) {
    const story = useStoryblokState(pageContext.story,location)
    const { data: dt, methods } = useContext(GlobalContext)
    const { lang: language } = dt

    const { data } = useStaticQuery(graphql`
    query last_banner_pricing {
    data : allStoryblokEntry(
        filter: { field_component: { eq: "Last Banner Section" } }
      ) {
        edges {
          node {
            id
            name
            slug
            field_component
            full_slug
            content
            internalId
            lang
          }
        }
      }
    }
  `)
  
  const blok = parse(data?.edges?.filter( i => i?.node?.lang === language)[0]?.node?.content) || {}
    
    return (
      <Layout meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }} className="layoutclass" location={location} full_slug={story?.full_slug} lang={story?.lang} pathname={pageContext.pathname}>
          <Pricing story={story} />
          <LastBanner blok={blok} />
      </Layout> 
    )
}
