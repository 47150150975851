import React, { useState } from 'react'
const getClass = (collapsible, collapsed) => {
    let classList = [`table-row`]
    if (collapsible) {
        classList.push(`collapsible`)
    }
    if (!collapsed) {
        classList.push(`active`)
    }
    return classList.join(' ')
}
const PricingTable = ({ collapsible, head, body }) => {
    const [collapsed, setCollapsed] = useState(true)

    return (
        <div className="table-content-section">
            <div className="table-toggle">
                <div className={getClass(collapsible,collapsed)} onClick={() => setCollapsed(!collapsed)}>
                    {head?.map((hd) => (
                        <div> {hd?.value} </div>
                    ))}
                </div>
                {(!collapsible || !collapsed) && body?.map((bd) => {
                    return (
                        <div className={collapsible ? 'collapsed table-row' : 'table-row'}>
                            {bd?.body?.map((item) => (
                                <div> {item?.value} </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default PricingTable